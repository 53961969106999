/*Bootstrap navbar for mobile*/
.navbar {
    font-weight: 300 !important;
    color: #282828;
    background-color: rgba(255, 255, 255, 0.90) !important;
    backdrop-filter: blur(14px);

}
.nav-link {
    font-size: 18px;
    font-weight: inherit !important;
}
.navbar-toggler-icon {
    width: 1.35em;
    height: 1.35em;
}

/*Custom navbar for desktop*/
.custom-navbar {
    position: fixed;
    transition: padding-top .5s;
    transition-timing-function: ease;
    z-index: 100;
}
.landing-nav {
    background-color: transparent;
    padding-top: 30px;
    border-bottom: 1px solid transparent;
}
.landing-nav > a {
    color: white;
    transition: background-color .5s;
}
.landing-nav > a:hover {
    background-color: rgba(255, 255, 255, .1);
}
.scrolled-nav {
    background-color: rgba(255, 255, 255, 0.90) !important;
    backdrop-filter: blur(14px);
    padding-top: 0px;
    border-bottom: 1px solid #f0f0f0;
}
.scrolled-nav > a{
    color: #282828;
}
.scrolled-nav > a:hover{
    background-color: #f9f9f9;
}
.custom-nav-link {
    display: inline-block;
    padding: 15px 70px;
    margin: 0px 0px;
    font-size: 18px;
}
.nav-name {
    font-size: 18px;
    font-weight: 300;
}