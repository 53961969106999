.About {
    background-color: #fafafa !important;
}
.richie-img {
    width: 200px;
    border-radius: 50%;
}
.description {
    font-size: 20px;
}
@media (max-width: 991px) {
    .description {
        font-size: 18px;
        text-align: left !important;
    }
}