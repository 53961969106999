.Landing {
    height: 100vh !important;
    padding-top: 16%;
    background-color: #282828 !important;
}
.name {
    font-size: 5em;
    letter-spacing: 2px;
    word-wrap: wrap;
    font-family: "Staatliches", "Muli", sans-serif;
}
.title {
    font-size: 1.4em;
}
.typed-cursor{
    color: #4286f4;
}
.splash-link {
    color: white;
    font-size: 1.8em;
    padding: 10px 15px;
    border: 1px solid white;
    margin-left: -1px;
}
.splash-link:hover{
    background-color: white;
    color: #282828;
}
.link-github{
    background-color: #282828;
}
.link-linkedin{
    background-color: #0073b1;
}
.link-linkedin:hover{
    color: #0073b1 !important;
}
.link-mail{
    background-color: #eb4034;
}
.link-mail:hover{
    color: #eb4034 !important;
}
.link-hover-label {
    font-size: 1.2em;
    font-family: "Staatliches", "Muli", sans-serif;
    display: inline-block;
}

@media (max-width: 991px) {
    .Landing {
        padding-top: 125px;
        height: auto !important;
    }
    .name {
        font-size: 2.75em;
        margin-bottom: 10px;
        letter-spacing: 0px;
    }
    .title {
        font-size: 1.2em;
    }
    .link-hover-label {
        display: none;
    }
}