.footer-email {
    font-size: 20px;
}
.star {
    font-size: 1.75em;
}
.star-hover {
    color: #f4f442;
}
.star-nohover {
    color: #f0f0f0;
}
.heart {
    color: #FF247F;
}
