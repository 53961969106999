html, body, #root {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    font-family: "Muli", sans-serif;
    font-weight: 200;
    font-size: 1em;
    padding: 0;
    margin: 0;
}
.h1 {
    font-weight: 200 !important;
}
a {
    text-decoration: none !important;
}

.scaled-py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.scaled-my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

@media (max-width: 991px) {
    .h1 {
        font-size: 2rem !important;
        font-weight: 300 !important;
    }
    .scaled-py-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .scaled-my-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
}